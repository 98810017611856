* {
  color: white;
}

body {
  padding: 0;
  margin: 0;

  background-color: #333;

  display: flex;
  justify-content: center;
}